import React from "react";

export default function Culture() {
  return (
    <section className="bg-[#F9F9F9] p-6 md:p-16 relative">
      <div className="w-full absolute flex justify-center items-center">
        <div className="w-[300px] h-[300px] md:w-[600px] md:h-[600px] mt-52 md:mt-10 bg-[#F4F4F4] rounded-full" />
      </div>
      <div className="flex flex-col md:flex-row md:items-center relative">
        <div className="hidden md:block absolute right-0 tracking-widest items-center origin-center rotate-90">
          who we are
        </div>
        <div className="text-4xl md:text-6xl flex-1 md:pr-40 leading-tight">
          Our culture, <br />
          values and <br />
          beliefs
        </div>
        <div className="flex-1 md:pr-40">
          <div className="py-6">
            <div className="text-2xl py-4 font-semibold">Ownership-thinking</div>
            <div>
              An 'owner-mindset' means the world to us. We are here to maximise benefits of your time, money and resources, by prioritising your business over our own.
            </div>
          </div>
          <div className="py-6">
            <div className="text-2xl py-4 font-semibold">Agile-thinking</div>
            <div>
              Agility is a core value, one that drives our engagement and operating models. We always respond to changing demands and needs fast and effectively.
            </div>
          </div>
          <div className="py-6">
            <div className="text-2xl py-4 font-semibold">Systems-thinking</div>
            <div>
              We have a systems approach to our engagement. Things must 'fit' - both from the larger picture as well as from the 'nuts and bolts'.
            </div>
          </div>
          <div className="py-6">
            <div className="text-2xl py-4 font-semibold">Growth-thinking</div>
            <div>
              To us 'growth' goes beyond revenue growth. We have a mindset that encompasses 'better & faster' in our hiring, technology, capacity, responsiveness and quality.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
