import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import IMAGES from "../../utils/images";
import BannerContainer from "../common/BannerContainer";

export default function WhoWeAre() {
  return (
    <div>
      <section>
        <div>
          <BannerContainer
            title="who we are"
            heading="We are a team with <br />
                  our skin in the game"
            caption="Obviously we are people that are <br/> passionate, focussed, creative, <br />
                cutting-edge, customer-centric, <br />and all that... <br />Are these optional today?"
          />
        </div>
        <div id="analytics-bg " className="mt-12 flex justify-end">
          <StaticImage
            src="https://firebasestorage.googleapis.com/v0/b/admin-upload-6e7b6.appspot.com/o/Thinklokal-website%2Fimages%2Fdiverse-people-working-office%20(1)-min.jpg?alt=media&token=920176fa-52b3-437e-a2b7-6a033a0a168e"
            className="object-fill"
            alt
          />
        </div>  
      </section>
      <section>
        <div>
          <div className="flex items-start md:items-center">
            <div className="p-6 md:p-24 md:pb-12 flex-1">
              <span className="text-[#57B847] font-semibold text-2xl">
                an analytics-first, and only, company
              </span>
              <div className="pt-12">
                <div className="title text-4xl md:text-6xl leading-tight">
                  Blending prople, technology and{" "}
                  <br className="hidden md:block" />
                  process for actionable insights
                  <span className="text-[#57B847] text-7xl leading-7">.</span>
                </div>
              </div>
              <p className="py-8 md:pl-40 leading-loose text-2xl">
                We have partnered with the best data analytics platforms, cloud
                services, data <br className="hidden lg:block" />
                warehouses and more. Combined with our experienced people you
                get an <br className="hidden lg:block" />
                unmatched as-a-service offering.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
