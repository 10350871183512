import React from "react";
import Footer from "../components/Footer";
import GetAQuote from "../components/home/GetAQuote";
import TopbarDark from "../components/TopbarDark";
import CarrerOpportunities from "../components/who_we_are/CarrerOpportunities";
import Culture from "../components/who_we_are/Culture";
import WhoWeAre from "../components/who_we_are/WhoWeAre";

//import People from "../components/home/People.js"

export default function Who_we_are() {
  return (
    <main className="overflow-x-hidden">
      <TopbarDark />
      <WhoWeAre />
      <Culture />
      <CarrerOpportunities />
      <GetAQuote />
      <Footer />
    </main>
  );
}
