import AniLink from "gatsby-plugin-transition-link/AniLink";
import React from "react";
import ICONS from "../../utils/icons";
import IMAGES from "../../utils/images";

export default function CarrerOpportunities() {
  return (
    <section>
      <div className="relative flex">
        <div className="absolute -bottom-10 right-0 flex md:top-0 md:left-0 flex-col md:h-1/2 md:w-1/2 bg-[#E21F2C] text-white px-8 md:pt-16 pb-8 z-10">
          <div className="text-xl md:text-3xl flex-1 pt-2 pb-12 md:pb-0">
            Discover our career opportunities
          </div>
          <div className="items-end text-right uppercase right-0 relative">
            <AniLink
              fade
              to="/careers"
              className="flex items-start md:items-center absolute right-0 md:bottom-0 md:text-xl font-semibold"
            >
              join our team
              <img
                src={ICONS.ArrowRight}
                className="w-10 md:w-16 md:mr-6 pl-4"
                alt="arrow right"
              />
            </AniLink>
          </div>
        </div>
        <img src={IMAGES.StudentImage} alt />
      </div>
    </section>
  );
}
